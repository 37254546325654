@media only screen and (max-width: 600px) {
  .profile_sidebar {
    display: none;
  }
  .profileSidebarContainer {
    width: 90vw;
  }
  .profileSidebarContainer div p {
    margin-left: 5vw;
  }
}/*# sourceMappingURL=profileSidebar.css.map */