.ProductContainer .ProductWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
.ProductContainer .ProductWrapper .ProductHeading {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.ProductContainer .ProductWrapper .ProductHeading h3 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
}
.ProductContainer .ProductWrapper .ProductCollection {
  border-radius: 0.4rem;
  box-shadow: 1px 1px 8px 1px rgb(229, 228, 228);
  background-color: white;
  min-width: 90vw;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;
}
.ProductContainer .ProductWrapper .ProductCollection .products {
  transition: all 0.45s ease-in-out;
  position: relative;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  margin-top: 5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ProductContainer .ProductWrapper .ProductCollection .products:hover {
  box-shadow: 0 0 10px rgb(124, 123, 123);
}
.ProductContainer .ProductWrapper .ProductCollection .products .fontawesomeIcon {
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  top: 5px;
  right: 10px;
  position: absolute;
  color: rgb(80, 79, 78);
}
.ProductContainer .ProductWrapper .ProductCollection .products .fontawesomeIcon:hover {
  color: rgb(236, 48, 48);
}
.ProductContainer .ProductWrapper .ProductCollection .products .productImg {
  cursor: pointer;
  width: 15rem;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productImg img {
  width: 100%;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productTitle {
  transition: all 0.45s ease-in-out;
  cursor: pointer;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 30ch;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productTitle:hover {
  color: rgb(210, 46, 46);
}
.ProductContainer .ProductWrapper .ProductCollection .products .productTitle h3 {
  font-size: 1rem;
  font-weight: 400;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productPrice {
  margin-top: 0.85rem;
  margin-bottom: 0.85rem;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productPrice p {
  color: red;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productPrice .underlinePrice {
  color: black;
  opacity: 0.35;
  text-decoration: line-through;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productBtn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.ProductContainer .ProductWrapper .ProductCollection .products .productBtn p {
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.3rem;
  color: white;
  background-color: rgb(97, 7, 7);
}
.ProductContainer .ProductWrapper .ProductCollection .products .productBtn p:hover {
  background-color: rgb(182, 22, 22);
}
.ProductContainer .ProductWrapper .ProductCollection .products .productBtn p .fontawesomeIcon_Cart {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 1200px) {
  .ProductContainer .ProductWrapper .ProductCollection {
    display: inline-grid;
    grid-template-columns: auto auto;
  }
  .ProductContainer .ProductWrapper .ProductCollection .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 800px) {
  .ProductContainer .ProductWrapper .ProductCollection {
    display: flex;
    flex-direction: column;
  }
  .ProductContainer .ProductWrapper .ProductCollection .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=topProduct.css.map */