.navContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  width: 100vw;

  // border: 1px solid red;

  .Wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    // border: 1px solid rgb(0, 170, 255);

    .navWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      // border: 1px solid rgb(0, 255, 55);

      .logo {
        &:hover {
          cursor: pointer;
        }
        width: 8rem;
        // border: 1px solid red;

        img {
          width: 100%;
        }
      }
      .search {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 0.75rem;
        .searchBar {
          width: 35rem;
          height: 2rem;
          padding-left: 0.5rem;
          border-radius: 0.35rem;
        }
        .fontawesomeIcon {
          &:hover {
            cursor: pointer;
          }
          margin-left: 0.5rem;
          width: 1rem;
          color: #fff;
        }
        .fontawesomeIcon_search {
          display: none;
        }
        .fontawesomeIcon_X {
          display: none;
        }
      }

      .avatar {
        margin-top: 0.5rem;
        &:hover {
          cursor: pointer;
        }
        display: flex;
        // border: 1px solid red;

        .button {
          &:hover {
            cursor: pointer;
          }
          margin-bottom: 2rem;
          margin-left: 0.35rem;
          padding-bottom: 1rem;
          border: none;
          color: #fff;
          background-color: rgb(0, 0, 0);
          .fontawesomeIcon {
            &:hover {
              cursor: pointer;
            }
            color: #fff;
            margin-left: 0.5rem;
            margin-top: 0.25rem;
          }
        }
        .fontawesomeIcon_Responsive {
          &:hover {
            cursor: pointer;
          }
          color: #fff;
          margin-left: 0.5rem;
          margin-top: 0.25rem;
          display: none;
        }
      }
    }

    .navList {
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        margin-bottom: 1rem;
        list-style-type: none;
        display: flex;

        li {
          margin-top: 1rem;
          margin-left: 5rem;
          margin-right: 5rem;
          a {
            transition: all 0.5s ease-in-out;
            &:hover {
              // color: rgb(234, 195, 150);
              color: rgb(200, 88, 65);
              cursor: pointer;
              .fontawesomeIcon {
                // color: rgb(234, 195, 150);
                color: rgb(200, 88, 65);
              }
            }
            color: rgb(255, 255, 255);
            text-decoration: none;

            .fontawesomeIcon {
              transition: all 0.5s ease-in-out;
              &:hover {
                cursor: pointer;
              }
              margin-right: 0.35rem;
              width: 1rem;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .navContainer {
    .Wrapper {
      display: flex;

      .navWrapper {
        margin-top: 0.25rem;
        display: flex;
        .logo {
          width: 5rem;
          margin-right: 50vw;
        }
        .search {
          margin: 20px 0 0 0;
          .fontawesomeIcon {
            display: none;
            margin-top: 0;
          }
          .fontawesomeIcon_search {
            margin-top: 0.25rem;
            display: block;
          }
          .searchBar {
            display: none;
          }
        }
        .avatar {
          margin-top: 0;
          margin-left: 5vw;
          padding-top: 10px;
          padding-bottom: 10px;
          button {
            display: none;
          }
          .fontawesomeIcon_Responsive {
            display: block;
          }
        }
      }

      .navList {
        z-index: 10000;
        background-color: black;
        width: 100vw;
        // height: 5rem;
        // padding: 1rem;
        position: fixed;
        bottom: -5px;
        left: 0;

        ul {
          margin-bottom: 0;
          li {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            margin-left: 0;
            margin-right: 8vw;
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
