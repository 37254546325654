.link {
  text-decoration: none;
  list-style-type: none;
}

.products {
  transition: all 0.45s ease-in-out;
  border-radius: 0.5rem;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.products:hover {
  box-shadow: 0 0 10px rgb(124, 123, 123);
}
.products .fontawesomeIcon-red {
  color: rgb(236, 48, 48);
}
.products .fontawesomeIcon-gray {
  color: rgb(80, 79, 78);
}
.products .fontawesomeIcon {
  z-index: 55;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  top: 5px;
  right: 10px;
  position: absolute;
}
.products .fontawesomeIcon:hover {
  color: rgb(236, 48, 48);
}
.products .productImg {
  cursor: pointer;
  width: 15rem;
  height: 15rem;
}
.products .productImg img {
  width: 100%;
  height: 100%;
}
.products .productTitle {
  transition: all 0.45s ease-in-out;
  cursor: pointer;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 30ch;
}
.products .productTitle:hover {
  color: rgb(210, 46, 46);
}
.products .productTitle h3 {
  color: black;
  opacity: 0.85;
  font-size: 1rem;
  font-weight: 400;
}
.products .productPrice {
  margin-top: 0.85rem;
  margin-bottom: 0.85rem;
}
.products .productPrice p {
  color: red;
}
.products .productPrice .underlinePrice {
  color: black;
  opacity: 0.35;
  text-decoration: line-through;
}
.products .Stars {
  margin-top: 0.25rem;
  margin-bottom: 0.45rem;
}
.products .Stars .fontawesomeIconStars {
  width: 1rem;
  cursor: pointer;
  color: rgb(251, 165, 60);
}
.products .productBtn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.products .productBtn button {
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.3rem;
  color: white;
  background-color: rgb(97, 7, 7);
}
.products .productBtn button:hover {
  background-color: rgb(182, 22, 22);
}
.products .productBtn button .fontawesomeIcon_Cart {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 1200px) {
  .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 800px) {
  .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=product.css.map */