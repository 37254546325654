@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */
*{
    margin: 0;
    padding: 0
    
}
body {

    font-family: "Poppins";

    /* font-family: 'Lato';    */
     box-sizing: border-box;
    background-color: rgb(249, 249, 249);
    scroll-behavior: smooth;
    
    /* background-color: rgb(30, 26, 26); */
}
.App{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}