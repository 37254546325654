.productContainer .productWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
.productContainer .productWrapper .productHeading {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.productContainer .productWrapper .productHeading h3 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
}
.productContainer .productWrapper .productCollection {
  background-color: white;
  box-shadow: 1px 1px 8px 1px rgb(229, 228, 228);
  border-radius: 0.4rem;
  min-width: 90vw;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

@media only screen and (max-width: 1200px) {
  .productContainer .productWrapper .productCollection {
    display: inline-grid;
    grid-template-columns: auto auto;
  }
  .productContainer .productWrapper .productCollection .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 800px) {
  .productContainer .productWrapper .productCollection {
    display: flex;
    flex-direction: column;
  }
  .productContainer .productWrapper .productCollection .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=Store.css.map */