.link {
  text-decoration: none;
  list-style-type: none;
}
.products {
  transition: all 0.45s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px rgb(124, 123, 123);
  }
  border-radius: 0.5rem;
  // width: fit-content;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  // margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  // height: 30rem;
  .fontawesomeIcon-red {
    color: rgb(236, 48, 48);
  }
  .fontawesomeIcon-gray {
    color: rgb(80, 79, 78);
  }
  .fontawesomeIcon {
    z-index: 55;
    transition: all 0.25s ease-in-out;
    // color: rgb(236, 48, 48);

    &:hover {
      color: rgb(236, 48, 48);
    }
    cursor: pointer;
    top: 5px;
    right: 10px;
    position: absolute;
  }
  .productImg {
    // overflow: inherit;
    cursor: pointer;
    width: 15rem;
    height: 15rem;
    // background-color: red;
    // box-shadow: 5rem black;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .productTitle {
    transition: all 0.45s ease-in-out;
    &:hover {
      color: rgb(210, 46, 46);
    }
    cursor: pointer;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 30ch;
    h3 {
      color: black;
      opacity: 0.85;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .productPrice {
    margin-top: 0.85rem;
    margin-bottom: 0.85rem;
    p {
      color: red;
    }
    .underlinePrice {
      color: black;
      opacity: 0.35;
      text-decoration: line-through;
    }
  }
  .Stars {
    margin-top: 0.25rem;
    margin-bottom: 0.45rem;
    // width: .5rem;
    .fontawesomeIconStars {
      width: 1rem;
      cursor: pointer;
      color: rgb(251, 165, 60);
    }
  }
  .productBtn {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    button {
      transition: all 0.25s ease-in-out;
      &:hover {
        background-color: rgb(182, 22, 22);
      }
      cursor: pointer;
      border: none;
      font-weight: 600;
      font-size: 1rem;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-radius: 0.3rem;
      color: white;
      background-color: rgb(97, 7, 7);
      .fontawesomeIcon_Cart {
        margin-right: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .products {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
