.ProductContainer {
  .ProductWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;

    .ProductHeading {
      margin-top: 2rem;
      margin-bottom: 1rem;

      h3 {
        font-size: 2rem;
        font-weight: 500;
        font-style: italic;
      }
    }
    .ProductCollection {
      border-radius: 0.4rem;
      box-shadow: 1px 1px 8px 1px rgb(229, 228, 228);
      background-color: white;
      min-width: 90vw;
      margin-bottom: 5rem;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
      // max-width: 1200px;
      // margin: 0 auto;
      .products {
        transition: all 0.45s ease-in-out;
        &:hover {
          box-shadow: 0 0 10px rgb(124, 123, 123);
        }
        position: relative;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
        margin-top: 5rem;
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .fontawesomeIcon {
          transition: all 0.25s ease-in-out;
          &:hover {
            color: rgb(236, 48, 48);
          }
          cursor: pointer;
          top: 5px;
          right: 10px;
          position: absolute;
          color: rgb(80, 79, 78);
        }
        .productImg {
          cursor: pointer;
          width: 15rem;
          img {
            width: 100%;
          }
        }
        .productTitle {
          transition: all 0.45s ease-in-out;
          &:hover {
            color: rgb(210, 46, 46);
          }
          cursor: pointer;
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
          width: 30ch;
          h3 {
            font-size: 1rem;
            font-weight: 400;
          }
        }
        .productPrice {
          margin-top: 0.85rem;
          margin-bottom: 0.85rem;
          p {
            color: red;
          }
          .underlinePrice {
            color: black;
            opacity: 0.35;
            text-decoration: line-through;
          }
        }
        .productBtn {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          p {
            transition: all 0.25s ease-in-out;
            &:hover {
              background-color: rgb(182, 22, 22);
            }
            cursor: pointer;
            font-weight: 600;
            font-size: 1rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            border-radius: 0.3rem;
            color: white;
            background-color: rgb(97, 7, 7);
            .fontawesomeIcon_Cart {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .ProductContainer {
    .ProductWrapper {
      .ProductCollection {
        display: inline-grid;
        grid-template-columns: auto auto;

        .products {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .ProductContainer {
    .ProductWrapper {
      .ProductCollection {
        display: flex;
        flex-direction: column;

        .products {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
